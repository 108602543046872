const URLSearch = new URLSearchParams(window.location.search);
const id = URLSearch.get("sy_id");
const utm_source = URLSearch.get("utm_source");

window.SoyPlayer.setup({
  id,
  elementId: "root",
  theme: "sk",
  utm_source,
  callback: {
    // CLICK_BANNER: undefined,
    CLICK_PRODUCT: (props) => {
      // const { currentTime, durationTime } =
      //   window.SoyPlayer.dispatch("VIDEO_STATUS");
      // let sy_time;
      // if (durationTime !== Infinity && durationTime - currentTime > 10) {
      //   sy_time = currentTime;
      // }
      let productLink = props.link;
      // window.location.href = productLink;
      const arr = productLink.split(".");

      /* 채널톡 관련 상품 URL은 토큰 보내지 않음 */
      if (arr.find((element) => element === "channel")) {
        window.location.href = productLink;
      } else {
        // sk에서 처리 완료 시 위에 코드로 바꾸기
        // const encodedToken = props.token ? encodeURIComponent(props.token) : "";
        // productLink +=
        //   (productLink.split("?")[1] ? "&" : "?") +
        //   `sy_id=${id}&token=${encodedToken}`;

        // sk 렌터가 다이렉트가 아닌데서 방송할 때는 token 무조건 빠져야됨. 핫픽스
        productLink += (productLink.split("?")[1] ? "&" : "?") + `sy_id=${id}`; // prod에만 반영
      }
      window.location.href = productLink;
    },
    // CLICK_SHARE: undefined,
    // CLICK_COUPON: (payload) => {
    //   console.log("COUPON_CLICKED");
    // },
    // ERROR: (payload) => {
    //   console.log("ERROR_MESSAGE");
    // },
  },
});

window.onpageshow = function (event) {
  if (event.persisted) {
    window.location.reload();
  }
};
